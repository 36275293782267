/*
 * @Author: wangqs 
 * @description 历史账单详情
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-16 09:34:13
 */
<template>
  <div
    class="page-box box"
    :style="`background: url(${VUE_APP_STATIC_URL}repay-all-bg.png) center center no-repeat;background-size: cover;`"
  >
    <div class="page-in-box box">
      <div class="top-box box">
        <div class="time-box">
          {{ query.year || '--' }} 年 {{ query.month || '--' }} 月
        </div>
      </div>
      <div class="bill-box box">
        <div class="img-box">
          <van-image :src="VUE_APP_STATIC_URL + 'repay-bill-icon.png'"></van-image>
        </div>
        <div class="text-a">
          账单已还清
        </div>
        <div class="text-b">
          账单金额：{{ parseFloat(amount || 0).toFixed(2) }} 元
        </div>
      </div>
    </div>

    <!-- 订单列表 -->
    <div class="list-box box">
      <template v-if="list && list.length > 0">
        <repay-item :list-data="list"></repay-item>
      </template>
      <div
        v-else
        style="background: #fff;"
        class="box"
      >
        <van-empty
        
          description="暂无明细"
        />
      </div>
    </div>
  </div>
</template>

<script>
const { VUE_APP_STATIC_URL } = process.env
import RepayItem from './components/repay-item.vue'
export default {
  name: 'Test',
  components: {
    RepayItem
  },
  data () {
    return {
      VUE_APP_STATIC_URL,
      list: [],
      amount: 0,
      query: {}
    }
  },
  computed: {
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      let query = this.query = this.$route.query
      let data = {
        annual: query.year,
        month: query.month
      }
      let res =  await this.$http.repayApi.historyDetail(data)
      console.log(res, 'res')
      if (res.code == 200) {
        for (let i of res.msg.repaymentPlanList || []) {
          i.show = false
        }
        this.list = res.msg.repaymentPlanList || []
        this.amount = res.msg.amount || 0
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  min-height: 100vh;
  padding: 50px 15px 15px;
  .page-in-box {
    background-color: #f5f5f5;
    min-height: 100%;
    .top-box {
      background: #fff;
      text-align: center;
      .time-box {
        background-color: #f8f8f8;
        line-height: 65px;
        height: 50px;
        font-size: 15px;
      }
    }
    .bill-box {
      margin-bottom: 10px;
      background: #fff;
      padding-top: 20px;
      text-align: center;
      padding-bottom: 20px;
      .img-box {
        height: 60px;
        width: 70px;
        margin: 0 auto;
        .van-image {
          width: 100%;
          height: 100%;
        }
      }
      .text-a {
        line-height: 20px;
        font-size: 15px;
        margin-top: 10px;
      }
      .text-b {
        line-height: 18px;
        font-size: 13px;
        margin-top: 8px;
        color: #646464;
      }
    }
  }
  .list-box {
    background-color: #f5f5f5;
  }
}

</style>
